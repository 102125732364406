@charset "utf-8";

/*=================================
  汎用クラスの定義
=================================*/
.float-l {
  float: left;
}
.float-r {
  float: right;
}
.inner-wrap {
  max-width: $base-width;
  margin: 0 auto;
}
.inner-wrap-sm {
 max-width: 870px;
 margin: 100px auto 30px;
}
.is-sp-only {
  display: none!important;
}
.is-txt-center {
  text-align: center;
}
.text--base {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}
.notes {
  font-size: 1.4rem;
  display: block;
}
.cf:after {
  content:" ";
  display:block;
  clear:both;
}
.is-flex{
  display: flex;
}
/*共通レイアウト*/
.layout--2col {
  max-width: $base-width;
  margin: 40px auto 56px;
  display: flex;
  align-items: flex-start;
  &__cont {
    width: calc(100% - 350px);
  }
  &.cont--r {
    flex-direction: row-reverse;
    .layout--2col__cont {
      margin-left: 50px;
    }
  }
  &.cont--l {
    .layout--2col__cont {
      margin-right: 50px;
    }
  }
}
.widelink__cover {
  img {
     width: 100%;
  }
}
/*パンくず*/
.breadcrumb {
  background: #fff;
  padding: 15px 0;
  font-size: 1.3rem;
  line-height: 1;
  ul {
    display: flex;
    align-items: center;
    li a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    li + li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: ">";
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
      }
    }
  }
}
/* =================================
  article common
================================= */
a {
  color: $accent-color;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #e5e5e5;
  margin: 20px 0 35px;
  table-layout: fixed;
  caption {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 10px 0;
  }
  thead {
    background: #f8fafb;
  }
  th {
    font-size: 1.5rem;
    line-height: 1.25;
    color: $accent-color-dark;
    text-align: left;
    padding: 15px 10px 13px 28px;
    background: #f8fafb;
    border: 1px solid #e5e5e5;
  }
  td {
    font-size: 1.5rem;
    line-height: 1.25;
    padding: 15px 20px 13px;
    border: 1px solid #e5e5e5;
    background: #fff;
  }
}
.yarpp-related {
  margin: 0!important;
}
/*タイトル*/
.ttl--underLine {
  font-size: 3.1rem;
  font-style: italic;
  line-height: 1;
  letter-spacing: 0.05em;
  color: $accent-color-dark;
  padding-bottom: 10px;
  margin-bottom: 36px;
  display: inline-block;
  border-bottom: 3px solid $accent-color-dark;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
/*ボタン*/
.btnWrap {
  margin: 28px auto 0;
  text-align: center;
}
.btnWrap--2col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 27px 0 0;
  > * + * {
    margin-left: 20px;
  }
}
.btn--radius {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  background: #0e9ce6;
  border-radius: 2px;
  padding: 9px 20px 9px 26px;
  border: 1px solid transparent;
  display: inline-block;
  &::after {
    content: "";
    width: 11px;
    height: 10px;
    background: url(/images/icon/icon_arrow_right_white.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  &:hover {
    background: #fff;
    border-color: $accent-color;
  }
}
.btn--form {
  position: relative;
  display: inline-block;
  border-radius: 2px;
  input {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.1em;
    text-align: center;
    color: #fff;
    padding: 15px 57px;
    margin: 0 auto;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
  &::before {
    content: "";
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-49%);
    right: 18px;
  }
  &::after {
    content: "";
    border-style: solid;
    border-width: 3px 0 3px 6px;
    border-color: transparent transparent transparent $accent-color;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    transform: translateY(-49%);
    right: 21px;
  }
  &.back {
    border-bottom: 5px solid #193e8c;
    input {
      background: $accent-color-dark;
    }
    &::before {
      right: initial;
      left: 18px;;
    }
    &::after {
      border-width: 3px 6px 3px 0;
      border-color: transparent $accent-color-dark transparent transparent ;
      right: initial;
      left: 21px;
    }
  }
  &.next {
    border-bottom: 5px solid #0d7fbb;
    input {
      background: $accent-color;
    }
  }
  &:hover {
    border-bottom-width: 0 !important;
    padding-top: 5px;
    &::before {
      transform: translateY(-35%);
    }
    &::after {
      transform: translateY(-12%);
    }
  }
}

@media screen and (max-width: $display-width-s){
  /* =================================
    base
  ================================= */
  html,body {
    min-width: 320px;
  }
  /* =================================
    汎用クラスの定義
  ================================= */
  .inner-wrap {
    width: 100%;
  }
  .inner-wrap-sm {
    margin: 30px auto;
   }
  .is-sp-only {
    display: block!important;
  }
  .is-pc-only {
    display: none!important;
  }
  .float-l,.float-r {
    float: none;
  } 
  .is-flex{
    display: block;
  }
  .widelink .widelink__cover {
    position: relative;
  }
  /*共通レイアウト*/
  .layout--2col {
    display: block;
    margin: 0 auto 30px;
    &__cont {
      width: 100%;
    }
    &.cont--r {
      .layout--2col__cont {
        margin-left: 0;
      }
    }
    &.cont--l {
      .layout--2col__cont {
        margin-right: 0;
      }
    }
  }
  /*パンくず*/
  .breadcrumb {
    padding: 11px 11px 8px;
    font-size: 1.2rem;
    overflow-x: scroll;
    white-space: nowrap;
  }

  /* =================================
    article common
  ================================= */
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0 30px;
    border: none;
    tr + tr {
      border-top: 1px solid #e5e4e4;
    }
    thead {
      border-bottom: 1px solid #e5e4e4;
      th {
        font-size: 1.5rem;
      }
    }
    th {
      font-size: 1.6rem;
      border-top: none;
      border-bottom: none;
      padding: 13px 10px 11px;
      min-width: initial;
      text-indent: 0;
      &::before {
        height: 25px;
        margin-right: 10px;
      }
    }
    td {
      border-top: none;
      border-bottom: none;
      padding: 13px 10px 11px;
    }
    caption {
      text-align: left;
      color: #fff;
      background: #2051b7;
      margin: 0;
      padding: 5px 11px;
    }
    >:first-child th {
      border-top: 1px solid #e5e5e5;
    }
    >:first-child td {
      border-top: 1px solid #e5e5e5;
    }
    >:last-child th {
      border-bottom: 1px solid #e5e5e5;
    }
    >:last-child td {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  /*タイトル*/
  .ttl--underLine {
    font-size: 2.2rem;
    padding-bottom: 6px;
    margin-bottom: 25px;
  }
  /*ボタン*/
  .btnWrap {
    margin: 20px auto 0;
  }
  .btnWrap--2col {
    display: block;
    margin: 25px 0 0;
    * {
      width: 100%;
    }
    > * + * {
      margin: 10px 0 0;
    }
  }
  .js--pd--sp {
    display: none;
  }
}
